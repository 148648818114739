/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import mainLogo from "../images/favicon.png"

function SEO({ description, lang, meta, title, imageUrl, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site: contentfulSite {
          title
          twitterAccount
          metaDescription {
            metaDescription
          }
        }
      }
    `
  )

  const metaDescription =
    description || site.metaDescription?.metaDescription || ""
  let metaImage = imageUrl || mainLogo
  if (metaImage.substring(0, 2) === "//") metaImage = "https:" + metaImage
  let metaKeywords = ""
  keywords.forEach((keyword, index) => {
    metaKeywords = `${metaKeywords}${keyword}${
      index !== keywords.length - 1 ? ", " : ""
    }`
  })
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.twitterAccount || "",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
